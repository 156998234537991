import React from "react"
import { Container, Row } from "reactstrap"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"

const UsagePolicyPage = ({intl}) =>

        <Layout>
        <SEO title="Terms" />
        {intl.locale === 'ar' ? 
            <div className="main">
                <div className="section text-center">
                    <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                        <Container>
                            <Row >
                                <h3 className="mt-0">بنود وشروط استخدام البوابة البيئية الخليجية</h3>
                            </Row>
                        </Container>
                    </div>
                    <br />
                    <Container style={{ textAlign: "right" }}>
                        <Row className="mb-2">
                            <p>عزيزي الزائر.. مرحباً بك في موقع البوابة البيئية لدول مجلس التعان الخليجي على شبكة الإنترنت، وشكراً جزيلاً على زيارتك.</p>
                        </Row>
                        <Row className="mb-2">
                            <p> ان استخدامك لهذه البوابة يعتبر موافقة منك على الالتزام قانونيًا بالبنود والشروط الخاصة بالاستخدام.</p>
                        </Row>
                        <Row className="mb-2">
                            <p>هذه البوابة البيئية للدول الأعضاء في مجلس التعاون لدول الخليج العربية (التي يشار إليها هنا بعبارة البوابة البيئية الخليجية) متاحة للاستخدام الشخصي لزائر البوابة. ويخضع دخول الزائر واستخدامه لهذه البوابة لبنود وشروط الاستخدام هذه، وكذلك يعد وصول الزائر ودخوله إلى البوابة موافقة دون قيد أو شرط على بنود وشروط الاستخدام سواء كان مستخدماً مسجلاً أم لم يكن، وتسري هذه الموافقة اعتباراً من تاريخ أول استخدام لهذه البوابة.</p>
                        </Row>
                        <Row className="mb-2">
                            <p>ويتضمن استخدام البوابة البيئية الخليجية عدداً من البنود والشروط التي تخضع لتحديثات وتغييرات مستمرة حسب الحاجة، ويصبح أي تعديل أو تحديث لأي من هذه البنود والشروط نافذة فور اعتمادها من إدارة البوابة، وهو ما يتطلب منك مراجعة مستمرة لشروط الاستخدام ومبادئ إخلاء المسؤولية لمعرفة أية تحديثات تتم عليها، إذ أن استمرارك في استخدام هذه البوابــة يعني اطلاعك وقبولك التام لأي تعديل تم على بنود وشروط استخدامها. علماً بأن هذه البنود والشروط تتضمن حقوق الملكية، كما أن إدارة البوابة غير مطالبة بالإعلان عن أية تحديثات تتم على تلك الشروط.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>القيود على الاستخدام:</h4>
                        </Row>
                        <Row>
                            <p>باستخدامك للبوابة البيئية الخليجية، تقر بالامتناع عما يلي:
                                <li>توفير أو تحميل ملفات تحتوي على برمجيات أو مواد أو بيانات أو معلومات أخرى ليست مملوكة لك أو لا تملك ترخيصاً بشأنها.</li>
                                <li>استخدام هذه البوابة بأية طريقة لإرسال أي بريد إلكتروني تجاري أو غير مرغوب فيه أو أية إساءة استخدام من هذا النوع للبوابة البيئية لدول مجلس التعاون الخليجي.</li>
                                <li>توفير أو تحميل ملفات على هذه البوابة تحتوي على فيروسات أو بيانات تالفة.</li>
                                <li>نشر أو إعلان أو توزيع أو تعميم مواد أو معلومات تحتوي تشويهاً للسمعة أو انتهاكاً للقوانين أو مواد إباحية أو بذيئة أو مخالفة للتعاليم الإسلامية أو للآداب العامة أو أي مواد أو معلومات غير قانونية من خلال هذه البوابة.</li>
                                <li>الاشتراك من خلال هذه البوابة في أنشطة غير مشروعة أو غير قانونية.</li>
                                <li>الإعلان على البوابة عن أي منتج أو خدمة تجعلها في وضع انتهاك لأي قانون أو نظام مطبق في أي مجال.</li>
                                <li>استخدام أية وسيلة أو برنامج أو إجراء لاعتراض أو محاولة اعتراض التشغيل الصحيح للبوابة البيئية لدول الخليجية.</li>
                                <li>القيام بأي إجراء يفرض حملاً غير معقول أو كبير أو بصورة غير مناسبة على البنية التحتية للبوابة.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>استخدام الروابط إلى البوابة البيئية الخليجية:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>يمنع نقل أو نسخ أي من محتويات البوابة البيئية الخليجية أو إنشاء أية روابط إلكترونية خاصة بها أو عرض أي منها.</li>
                                <li>يمكن وضع روابط خاصة بالبوابة البيئية الخليجية في أية مواقع أخرى لا تتعارض في أهدافها وتوجهها العام مع أهداف وسياسات وأطر عمل البوابة.</li>
                                <li>لا تعتبر البوابة البيئية الخليجية بأي حال من الأحوال مشاركة أو مرتبطة بأي شكل كان بأية علامات أو شعارات أو رموز تجارية أو خدمية أو أية وسائل أخرى مستخدمة أو تظهر على مواقع الويب المرتبطة بهذه البوابة أو أي من محتوياتها.</li>
                                <li>تحتفظ البوابة البيئية لدول مجلس التعاون الخليجي بكامل حقوقها في إيقاف وإعاقة أي ارتباط بأي شكل من الأشكال من أي موقع يحتوي على مواضيع غير ملائمة أو فاضحة أو متعدية أو بذيئة أو إباحية أو غير لائقة أو غير مقبولة أو غير قانونية، أو أسماء أو مواد أو معلومات تخالف أي قانون أو تنتهك أية حقوق للملكية الفكرية أو لحقوق الخصوصية.</li>
                                <li>تحتفظ البوابة بحق تعطيل أي ارتباط بأي شكل من الأشكال غير مصرح به ولا تتحمل أية مسئولية عن المحتويات المتوفرة في أي موقع آخر يتم الوصول إليه عبر هذه البوابة أو الوصول منه لهذه البوابة.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>الحماية من الفيروسات:</h4>
                        </Row>
                        <Row>
                            <p>
                                يبذل فريق العمل كل الجهود لفحص واختبار محتويات هذه البوابة في كل مراحل الإنتاج. ومع ذلك ننصح بأن يقوم الزائر دائما بتشغيل برنامج مضاد للفيروسات على كل المواد التي يتم إنزالها من الإنترنت. ولا تعتبر البوابة مسئولة عن أية خسارة أو انقطاع أو تلف لبيانات الزائر أو جهاز الحاسب لديه والذي قد يحدث أثناء الاتصال بهذه البوابة أو عند استخدام مادة واردة منها.
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>التنازل عن المطالبات:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>إن البوابة البيئية الخليجية والخدمات والمعلومات والمواد المتاحة بها أو التي يمكن الوصول إليها من خلال البوابة يتم توفيرها لاستخدامكم الشخصي " كما هي متاحة" دون ضمانات من أي نوع. </li>
                                <li>لا يمكننا أن نضمن أو أن نتحمل المسؤولية عن أية انقطاعات أو أخطاء أو تجاوزات قد تنشأ عن استخدام هذه البوابة أو محتوياتها أو أي موقع يرتبط بها سواء علمنا بها أو بدون علم منا.</li>
                                <li>إن أية اتصالات أو معلومات قد يقوم المستخدم بإرسالها من خلال هذه البوابة لن يكون له الحق في ملكيتها أو حق ضمان سريتها. كما أن أي استخدام أو استخدام تفاعلي تتضمنه هذه البوابة لا تضمن ولا يقصد بها أن تضمن للمستخدم أي حقوق أو تراخيص أو أية امتيازات من أي نوع.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>حدود المسئولية:</h4>
                        </Row>
                        <Row>
                            <p>
                                اللجوء إلى هذه البوابة يظل على مسئوليتك الخاصة، ونحن لا نكون بأي حال من الأحوال مسئولين عن أية خسارة أو ضرر من أي نوع قد تتكبده بسبب استخدامك أو زيارتك للبوابة أو اعتمادك على أي بيان أو رأي أو إعلان في البوابة أو ما قد ينجم عن أي تأخير في التشغيل أو تعثر الاتصال أو مشاكل الدخول إلى شبكة الإنترنت أو أعطال المعدات أو البرامج أو سلوك أو  أفكار أي شخص يدخل إلى هذه البوابة وبهذا تقر هنا وتوافق على أن وسيلتكم الحصرية والوحيدة لعلاج أي ضرر أو خسارة قد تحدث نتيجة دخولك أو استخدامك لهذه البوابة هي الامتناع عن استخدامها أو الدخول إليها أو عدم الاستمرار في ذلك.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>التعويض:</h4>
                        </Row>
                        <Row>
                            <p>بهذا تقر بعدم اتخاذ أي إجراء ضد البوابة البيئية لدول مجلس التعاون الخليجي أو أي من إدارتها وتعويضها وتأمينها من المسئولية وكذلك أية جهات أو موظفين أو وكلاء يكونون مسئولين عن إدارة أو صيانة أو تحديث أو تقديم البوابة، وذلك من كافة الالتزامات والمسئوليات التي قد تطرأ فيما يتصل بأية مطالبة تنشأ عن أي إخلال من جانبك ببنود وشروط الاستخدام أو أي من القوانين السارية سواء في دول مجلس التعاون الخليجي أو المكان الذي تقيم فيه</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>إنهاء الاستخدام:</h4>
                        </Row>
                        <Row>
                            <p>يجوز لإدارة البوابة وحسب تقديرها المطلق إنهاء أو تقييد أو إيقاف الحق في الدخول إليها واستخدامها دون إشعار ولأي سبب بما في ذلك مخالفة شروط وبنود الاستخدام أو أي سلوك آخر قد نعتبره حسب تقديرنا الخاص غير قانوني أو مضرًا بالآخرين، وفي حالة الإنهاء، فإنه لن يكون مصرحاً لك بالدخول إلى هذه البوابة.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>المرجعية القضائية:</h4>
                        </Row>
                        <Row>
                            <p>في ضوء ما تقدم فإنك مستخدم توافق على الخضوع حصريًا للسلطات القضائية في الدولة فيما يتعلق بكافة المطالبات والخلافات التي تنشأ عن استخدامك لهذه البوابة، علماً بأن اللغة العربية ستكون هي اللغة الرسمية المستخدمة لحل أية خلافات تنشأ عن استخدامك للبوابة أو أي من محتوياتها.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>بنود وشروط عامة:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>جميع المواد والمعلومات المتوفرة على البوابة غير هادفة للربح.</li>
                                <li>اللغة العربية هي اللغة الأساسية لاستخدام البوابة والاستفادة من كل المواد المنشورة عليها، ويهدف ترجمة أي من هذه المواد لتقديم خدمة مضافة، وعليه فلا يتم الاستناد إلى الترجمة المتوفرة في تفسير أي خلاف حول ما تتضمنه البوابة من محتوى.</li>
                                <li>كل الاتفاقيات أو التشريعات المنشورة على البوابة قد تخضع لترجمة لتفسير معانيها بهدف زيادة الفائدة، غير أن النص العربي لكل تلك اللوائح والقوانين يشكل المرجعية الأساسية، وعليه فلا يمكن بأي حال الاعتماد على الترجمة التفسيرية الخاصة بها لاستنباط أية معلومات أو تفاصيل.</li>
                                <li>لإدارة البوابة البيئية الخليجية الحق الكامل في حذف أو عدم نشر أية تعليقات أو مشاركات لمستخدمي البوابة تراها الإدارة غير مناسبة.</li>

                            </p>
                        </Row>

                    </Container>
                </div>
            </div>
        :
            <div className="main">
                <div className="section text-center">
                    <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                        <Container>
                            <Row >
                                <h3 className="mt-0">Terms and conditions of use of the Gulf Environmental Portal</h3>
                            </Row>
                        </Container>
                    </div>
                    <br />
                    <Container style={{ textAlign: "left" }}>
                        <Row className="mb-2">
                        <p>Dear visitor... Welcome to the Gulf Cooperation Council Environmental Portal website, and thank you very much for your visit. </p>
                        </Row>
                        <Row className="mb-2">
                        <p>By using this portal, you agree to be legally bound by the terms and conditions of use.</p>
                        </Row>
                        <Row className="mb-2">
                            <p>This environmental portal for the member states of the Cooperation Council for the Arab States of the Gulf (hereinafter referred to as the Gulf Environmental Portal) is available for the personal use of the portal visitor. The visitor's entry and use of this portal is subject to these terms and conditions of use, and the visitor's access and entry to the portal is an unconditional acceptance of the terms and conditions of use, whether he is a registered user or not, and this approval is effective from the date of the first use of this portal.</p>
                        </Row>
                        <Row className="mb-2">
                        <p>
                        The use of the Gulf Environmental Portal includes a number of terms and conditions that are subject to continuous updates and changes as needed, and any modification or update of any of these terms and conditions becomes effective immediately upon approval by the portal management, which requires you to constantly review the terms of use and the principles of disclaimer to see any updates made on them Your continued use of this portal means your full knowledge and acceptance of any modification made to the terms and conditions of its use. Note that these terms and conditions include property rights, and the portal management is not required to announce any updates to these terms.
                        </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Restrictions on use:</h4>
                        </Row>
                        <Row>
                            <p>By using the Gulf Environmental Portal, you agree to refrain from the following:
                                <li>Provide or upload files that contain software, material, data or other information that you do not own or do not have a license for.</li>
                                <li>Using this portal in any way to send any commercial or unsolicited e-mail or any misuse of this kind to the environmental portal for the countries of the Gulf Cooperation Council.</li>
                                <li>Publishing, advertising, distributing or circulating material or information containing defamation, violating laws, pornographic, obscene, violating Islamic teachings or public morals, or any illegal material or information through this portal.</li>
                                <li>Participation through this portal in illegal or illegal activities.
                                </li>
                                <li>Advertising on the portal about any product or service that makes it in violation of any law or regulation applied in any field.</li>
                                <li>Use any means, program or procedure to interfere or attempt to interfere with the proper operation of the GCC Environmental Portal.</li>
                                <li>Take any action that imposes an unreasonable, large or inappropriate load on the portal's infrastructure.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Use of links to the Gulf Environmental Portal:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>It is prohibited to transfer or copy any of the contents of the Gulf Environmental Portal, or to create or display any electronic links related to it.</li>
                                <li>Links to the Gulf Environmental Portal may be placed on any other sites that do not conflict in its objectives and general orientation with the goals, policies and frameworks of the Portal.</li>
                                <li>The Gulf Environmental Portal is not in any way involved or associated in any way with any marks, logos, commercial or service symbols or any other means used or appearing on the websites linked to this portal or any of its contents.</li>
                                <li>The Gulf Cooperation Council Environmental Portal reserves all its rights to stop and block any link in any way from any site that contains inappropriate, obscene, transgressive, obscene, pornographic, indecent, unacceptable or illegal topics, names, material or information Violate any law or violate any intellectual property rights or privacy rights.</li>
                                <li>The portal reserves the right to disable any link in any unauthorized way and does not bear any responsibility for the contents available on any other website accessed through this portal or from this portal.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Virus protection:</h4>
                        </Row>
                        <Row>
                            <p>
                                The work team makes every effort to check and test the contents of this portal at all stages of production, however we recommend that the visitor always run an anti-virus program on all material downloaded from the Internet. The portal is not responsible for any loss, interruption or damage to the visitor's data or his computer, which may occur while connecting to this portal or when using material from it.
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Waiver of Claims:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>The Gulf Environmental Portal and the services, information and material available on it or that can be accessed through the portal are provided for your personal use "as available" without guarantees of any kind</li>
                                <li>We cannot guarantee or be liable for any interruptions, errors or transgressions that may arise from the use of this portal or its contents or any site linked to it, whether or not we are aware of it.</li>
                                <li>Any communications or information that the user may send through this portal shall not have the right to own it or to guarantee its confidentiality. Also, any use or interactive use contained in this portal does not guarantee and is not intended to guarantee the user any rights, licenses or any privileges of any kind.</li>
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Limitation of Liability:</h4>
                        </Row>
                        <Row>
                            <p>
                                Resorting to this portal remains at your own risk, and we are not in any way responsible for any loss or damage of any kind that you may incur due to your use or visit to the portal or your reliance on any statement, opinion or announcement on the portal or what may result from any delay In operation, communication failure, Internet access problems, equipment or software malfunctions, the behavior or thoughts of any person who accesses this portal, and hereby you acknowledge and agree that your exclusive and only means of remedying any damage or loss that may occur as a result of your entry or use of this portal is to refrain from using it, accessing it, or not continuing to do so.
                            </p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Compensation:</h4>
                        </Row>
                        <Row>
                            <p>You hereby acknowledge not to take any action against the environmental portal for the countries of the Gulf Cooperation Council or any of its management and to compensate and secure it from liability, as well as any parties, employees or agents who are responsible for managing, maintaining, updating or presenting the portal, from all obligations and responsibilities that may arise in connection with Any claim arising from any breach by you of the Terms and Conditions of Use or of any applicable laws whether in the countries of the Gulf Cooperation Council or the place in which you reside</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Termination of use:</h4>
                        </Row>
                        <Row>
                            <p>The portal management may, at its absolute discretion, terminate, restrict or suspend the right to access and use it without notice and for any reason, including violating the terms and conditions of use or any other behavior that we may consider, in our sole discretion, illegal or harmful to others, and in the event of termination, it will not be authorized You have access to this portal.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>Judicial reference:</h4>
                        </Row>
                        <Row>
                            <p>In light of what you do, you as a user agree to submit exclusively to the judicial authorities in the country with regard to all claims and disputes that arise from your use of this portal, bearing in mind that the Arabic language will be the official language used to resolve any disputes arising from your use of the portal or any of its contents.</p>
                        </Row>

                        <Row className="mt-2">
                            <h4>General Terms and Conditions:</h4>
                        </Row>
                        <Row>
                            <p>
                                <li>All material and information available on the portal are non-profit.</li>
                                <li>The Arabic language is the primary language for using the portal and benefiting from all the material published on it. The translation of any of this material is intended to provide an added service. Therefore, the available translation is not based on the interpretation of any dispute about the content of the portal.</li>
                                <li>All agreements or legislation published on the portal may be subject to a translation to interpret their meanings with the aim of increasing the benefit. However, the Arabic text of all these regulations and laws constitutes the main reference, and therefore it is not possible in any way to rely on its interpretative translation to elicit any information or details.</li>
                                <li>The administration of the Gulf Environmental Portal has the full right to delete or not publish any comments or posts for the users of the portal that the administration deems inappropriate.</li>

                            </p>
                        </Row>

                    </Container>
                </div>
            </div>
        }
    </Layout >



export default injectIntl(UsagePolicyPage)
